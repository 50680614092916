<template>
  <el-card class="form-container" shadow="never" style="width: 98%">
    <el-form :model="userSettingModel" ref="userSettingForm" label-width="200px">
      <el-form-item label="用户下载次数超过：" >
        <el-input v-model="userSettingModel.down_number" min="0" type="number" style="width: 30%">
          <template v-slot:append>次</template>
        </el-input>
        <span style="margin-left: 10px;">不允许下载</span>
      </el-form-item>
      <el-form-item label="用户预览次数超过：" >
        <el-input v-model="userSettingModel.preview_number" min="0" type="number" style="width: 30%">
          <template v-slot:append>次</template>
        </el-input>
        <span style="margin-left: 10px;">不允许预览</span>
      </el-form-item>
      <el-form-item label="会员金额：">
        <el-input v-model="userSettingModel.member_money" type="number" style="width: 30%">
          <template v-slot:append>元</template>
        </el-input>
      </el-form-item>
<!--      <el-form-item label="会员单日下载次数超过：" >
        <el-input v-model="userSettingModel.member_down_number" min="0" type="number" style="width: 30%">
          <template v-slot:append>次</template>
        </el-input>
        <span style="margin-left: 10px;">不允许下载</span>
      </el-form-item>
      <el-form-item label="会员预览次数超过：" >
        <el-input v-model="userSettingModel.member_preview_number" min="0" type="number" style="width: 30%">
          <template v-slot:append>次</template>
        </el-input>
        <span style="margin-left: 10px;">不允许预览</span>
      </el-form-item>-->
      <el-form-item label="文案：" >
        <el-input v-model="userSettingModel.back_content" style="width: 30%" clearable></el-input>
      </el-form-item>
      <el-row>
        <el-col :span="8" v-if="!userSettingModel.back_pic" >
          <el-form-item label="产品图片：" >
            <el-upload ref="uploadForm" v-model="userSettingModel.back_pic" drag
                       action="#"
                       :file-list="fileListShow"
                       :auto-upload="false"
                       :before-remove="handleRemove"
                       :on-change="fileChange"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="userSettingModel.back_pic" >
          <el-form-item label="已传图片：" >
            <img style="width: 100%" :src="userSettingModel.back_pic"  class="avatar" >
            <span @click="handleDelete">删除</span>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item>
        <el-button type="primary" @click="handleDialogConfirm('userSettingForm')">提交</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>

import {getUserSetting, updateUserSetting} from '@/api/userSetting';

const defaultUserSetting = {
  id: null,
  down_number: 0,
  preview_number: 0,
  member_money: 0,
  member_down_number: 0,
  member_preview_number: 0,
  back_pic:"",
  back_content:""
};

export default {
  name: 'userSetting',
  components: {},
  data() {
    return {
      userSettingModel: Object.assign({}, defaultUserSetting),

      fileListShow:[],
      fileList:[],
      editProductPic:"",

    }
  },
  created() {

    this.getDetail();
  },
  methods: {
    //文件上传
    fileChange(file, fileList) {
      this.fileList = [];
      this.fileList.push(file);
      this.fileListShow = [];
      this.fileListShow.push({
        name: file["name"],
      })

    },
    //文件删除
    handleRemove(file, fileList) {
      // 通过file.name或者其他属性来匹配需要删除的文件
      this.fileList = this.fileList.filter(item => item.name !== file.name);
    },
    handleDelete(){
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        //删除附件
        this.editProductPic = this.userSettingModel.back_pic;
        this.userSettingModel.back_pic = "";
        this.$forceUpdate();   // 强制更新

      });
    },
    getDetail(){
      let data =  {
        "dataId": 1,
      };
      getUserSetting(data).then(response=>{
            if (response.code === '0') {
              this.userSettingModel = response.data;
            }
      })
    },
    //提交操作
    handleDialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          if (!this.userSettingModel.member_money || this.userSettingModel.member_money <=0) {
            this.$message.error("请填写会员金额")
            return
          }

          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {


            const form = new FormData();
            for (let i = 0; i < this.fileList.length; i++) {
              form.append('file[]', this.fileList[i].raw);
            }
            form.append('id',this.userSettingModel.id);
            form.append('down_number',this.userSettingModel.down_number);
            form.append('preview_number',this.userSettingModel.preview_number);
            form.append('member_money',this.userSettingModel.member_money);
            form.append('member_down_number',this.userSettingModel.member_down_number);
            form.append('member_preview_number',this.userSettingModel.member_preview_number);
            form.append('back_content',this.userSettingModel.back_content);
            form.append('back_pic',this.userSettingModel.back_pic);
            form.append('delete_product_pic',this.editProductPic);

            updateUserSetting(form).then(res => {
              if (res.code === '0') {
                this.$message({
                  message: '修改成功',
                  type: 'success',
                });
              }else {
                this.$message({
                  type: "error",
                  message: res.msg
                })
              }
              this.getDetail();
              this.fileList = [];
              this.fileListShow = [];
            });

          });
        }
      });
    },

  }
}
</script>
<style scoped>

</style>
